
  export default function decimal(valor) {
      return  Math.round(valor);
     /*  if(valor.lenght > 2)
       {
             let text_number = valor.toString();
                 text_number =  text_number.substring(0, texto.value.length - 2) + "00";
             let number   = text_number.parseInt(text_number);
             return cin_decimal(number);
       }else{
             return cin_decimal(0,valor)
       } */
    }

  /*  function cin_decimal(valor, $corto){
           if(corto >= 50) return valor + 100;  
           if(corto >= 25) return valor + 50;
           else return 0;
    } */

  