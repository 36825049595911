import {FETCH_REFERRAL_ID,EDIT_REFERRAL_ID,FETCH_REFERRAL_ID_SUCCESS,FETCH_REFERRAL_ID_FAILED, FETCH_REFERRAL_LIST_SUCCESS,
        FETCH_REFERRAL_LIST_SUCCESS2, FETCH_REFERRAL_LIST_SUCCESS3, FETCH_SPONSOR   } from "../store/types"
import {firebase} from "../config/configureFirebase";
import { onValue,push,set , getDatabase, ref } from "firebase/database";



export const clear_referral = () => (dispatch) => {

   
};

export const fetchusedreferral = ()=>(dispatch)=>{
    const {
        usedreferralRef
    } = firebase;

    dispatch ({
        type:FETCH_REFERRAL_ID,
        payload:null
    })

    onValue (usedreferralRef,snapshot=>{
        if (snapshot.val()) {
            const data = snapshot.val();
            const arr = Object.keys(data).map(i => {
              data[i].id = i
              return data[i]
            });
            dispatch({
              type: FETCH_REFERRAL_ID_SUCCESS,
              payload: arr.reverse()
            });
          } else {
            dispatch({
              type: FETCH_REFERRAL_ID_FAILED,
              payload: "No SOS available."
            });
          }
        // if(snapshot.val()){
        //     const data = snapshot.val();
        //     const arr = Object.keys(data).map(i=>{
        //         data[i].id = i;
        //         return data[i]
        //     });
        //     dispatch({
        //         type:FETCH_REFERRAL_ID_SUCCESS,
        //         payload:arr.reverse()
        //     });
        // }
        // else {
        //     dispatch({
        //         type:FETCH_REFERRAL_ID_FAILED,
        //         payload:"No referral id used"
        //     })
        // }
    })
}

export const listReferral  = (id)=>async(dispatch)=>{
            const {
              listReferralRef,
              referes2
            } = firebase;

            dispatch ({
              type:FETCH_REFERRAL_LIST_SUCCESS,
              payload:null
           })
      
           let level1 = null;
          await  onValue (listReferralRef(id),snapshot=>{
            if (snapshot.val()) {
                const data = snapshot.val();
                const arr = Object.keys(data).map(i => {
                  data[i].id = i
                  return data[i]
                });
                level1 = arr.reverse();

                dispatch ({
                  type:FETCH_REFERRAL_LIST_SUCCESS,
                  payload:level1
               })
            

               /*
                const lv = [];
                const idarray = "";
                level1.forEach(async (dat) => {
                       let uid= dat.id;
                       if(idarray != "") idarray += ",";
                        idarray += uid;
                  });

                onValue (referes2(idarray),snapshot=>{
                                      if (snapshot.val()) {
                                            const data = snapshot.val();
                                            const arr = Object.keys(data).map(i => {
                                                    data[i].id = i
                                                    return data[i]
                                            });
                                              lv =arr.reverse();
                                            dispatch({
                                              type: FETCH_REFERRAL_LIST_SUCCESS2,
                                              payload: lv
                                            });
                                      }
                  }); 
                  */

                

              } else {
                dispatch({
                  type: FETCH_REFERRAL_ID_FAILED,
                  payload: "No SOS available."
                });
              } 
        })    
}

export const listReferral2  = (array)=>async (dispatch)=>{
        const {
          listReferralRef,
        } = firebase;
        
        dispatch ({
            type:FETCH_REFERRAL_LIST_SUCCESS2,
            payload:null
        })
        const lv = [];
        array.forEach(async (dat) => {
               let uid= dat.id;
                await   onValue (listReferralRef(uid),snapshot=>{
                              if (snapshot.val()) {
                                    const data = snapshot.val();
                                    const arr = Object.keys(data).map(i => {
                                            data[i].id = i
                                            return data[i]
                                    });
                                    arr.forEach((ele)=>{
                                        lv.push(ele)
                                    })
                              }
                });
                dispatch({
                  type: FETCH_REFERRAL_LIST_SUCCESS2,
                  payload: lv
                });
           })
     }

     export const lista_id = (valor)=>async(dispatch)=>{
          const {
            listReferralRef,
          } = firebase;
          
            dispatch ({
              type:FETCH_REFERRAL_LIST_SUCCESS2,
              payload:null
          })
          const lv = [];
          let uid= valor;
          await   onValue (listReferralRef(uid),snapshot=>{
                        if (snapshot.val()) {
                              const data = snapshot.val();
                              const arr = Object.keys(data).map(i => {
                                      data[i].id = i
                                      return data[i]
                              });


                              arr.forEach((ele)=>{
                                  lv.push(ele)
                              })
                        }
          });

          dispatch({
            type: FETCH_REFERRAL_LIST_SUCCESS2,
            payload: lv
          });
     }

     export const lista_id2 = (valor)=>async(dispatch)=>{
      const {
        listReferralRef,
      } = firebase;

        dispatch ({
          type:FETCH_REFERRAL_LIST_SUCCESS3,
          payload:null
      })
      
      const lv = [];
      let uid= valor;
      await   onValue (listReferralRef(uid),snapshot=>{
                    if (snapshot.val()) {
                          const data = snapshot.val();
                          const arr = Object.keys(data).map(i => {
                                  data[i].id = i
                                  return data[i]
                          });
                          arr.forEach((ele)=>{
                              lv.push(ele)
                          })
                    }
      });

      dispatch({
        type: FETCH_REFERRAL_LIST_SUCCESS3,
        payload: lv
      });
 }

  
     export const listReferral3  = (array)=>async (dispatch)=>{
      const {
        listReferralRef,
      } = firebase;
      
      dispatch ({
          type:FETCH_REFERRAL_LIST_SUCCESS3,
          payload:null
      })
      const lv = [];
      array.forEach(async (dat) => {
             let uid= dat.id;
              await   onValue (listReferralRef(uid),snapshot=>{
                            if (snapshot.val()) {
                                  const data = snapshot.val();
                                  const arr = Object.keys(data).map(i => {
                                          data[i].id = i
                                          return data[i]
                                  });
                                  arr.forEach((ele)=>{
                                      lv.push(ele)
                                  })
                            }
              });
              dispatch({
                type: FETCH_REFERRAL_LIST_SUCCESS3,
                payload: lv
              });
         })
   }

  export const sponsor =  (id)=>async(dispatch)=>{
     
        const {
          singleUserRef,
        } = firebase;


        dispatch ({
            type:FETCH_SPONSOR,
            payload:""
        })
          
          let spon;
        await  onValue (singleUserRef(id),snapshot=>{

              if (snapshot.val()) {
                        const data = snapshot.val();

                        dispatch ({
                          type:FETCH_SPONSOR,
                          payload:data.firstName+" "+data.lastName
                      })

                }else{
                    console.log("error");
                }

      })
    
  }


export const editreferral = (users,method)=>(dispatch)=>{
    const {
        usedreferralRef,
    } = firebase;

    dispatch({
        type:EDIT_REFERRAL_ID,
        payload:{method,users}
    });
    if(method ==='Add'){
        push(usedreferralRef,users)
        
    }
}